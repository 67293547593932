<template>
    <div id="signup">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader :isPaid="isPaid"></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content section-title">
                                <h3 class="wow fadeInUp text-white">SIGN UP FOR THE INGOMU APP TODAY!</h3>
                                <h1 class="wow fadeInUp">{{ this.headerTitle }}</h1>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->


        <!-- Page Service Single Start -->
        <div class="page-service-single pb-0" id="description">
            <div class="container">
                <div class="row">

                    <div class="col-lg-9 wow fadeInUp" data-wow-delay="0.25s">
                        <!-- Service Single Content Start -->
                        <div class="service-single-content bg-section w-100" v-html="nl2br(description)"></div>
                        <!-- Service Single Content End -->
                    </div>

                    <div class="col-lg-3">
                        <!-- Service Category List Start -->
                        <div class="service-catagery-list wow fadeInUp">
                            <img :src="corpImage" alt="">
                        </div>
                        <!-- Service Category List End -->

                        <!-- Sidebar CTA Box Start -->
                        <div class="sidebar-cta-box wow fadeInUp" data-wow-delay="0.25s"
                            v-if="videourl != '' && videothumbnail != ''">
                            <!-- Sidebar CTA Body Start -->
                            <div class="sidebar-cta-Body">
                                <div class="intro-video-box">
                                    <!-- Sidebar Image Start -->
                                    <div class="sidebar-img">
                                        <a :href="videourl"
                                            class="popup-video" data-cursor-text="Play">
                                            <div class="card">
                                                <img :src="videothumbnail" alt="" class="card-img drk">
                                                <img src="../../../assets/v2/images/icon/170.svg" alt=""
                                                    class="card-img-overlay w-auto">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- Sidebar CTA Body End -->
                        </div>
                        <!-- Sidebar CTA Box End -->

                    </div>

                </div>
            </div>
        </div>
        <!-- Page Service Single End -->

        <!-- Our Pricing Section Start -->
        <div class="our-pricing pb-0" id="pricing" v-if="isPaid">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <!-- Pricing Content Start -->
                        <div class="pricing-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">PRICING</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Affordable Coaching<br><span>for Everyone</span>
                                </h2>
                                <p class="wow fadeInUp" data-wow-delay="0.5s">Embark on your coaching journey today and take advantage of this exclusive limited-time pricing. Your path to growth and success starts here!
                                </p>
                            </div>
                            <!-- Section Title End -->

                            <!-- Pricing Btn Start -->
                            <router-link :to="{ name: 'contact' }" class="btn-default textnone">Get in
                                Touch</router-link>
                            <!-- Pricing Btn End -->
                        </div>
                        <!-- Pricing Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Pricing Box List Start -->
                        <div class="pricing-box-list">
                            <!-- Pricing Box Start -->
                            <div class="pricing-box wow fadeInUp">
                                <!-- Pricing Box Content Start -->
                                <div class="pricing-box-content">
                                    <!-- Pricing Title Start -->
                                    <div class="pricing-title">
                                        <p>Semi-Annual</p>
                                        <h2><sup>$</sup>{{ priceForm.semiPrice }}<sub>/6 months</sub></h2>
                                    </div>
                                    <!-- Pricing Title End -->

                                    <!-- Pricing Button Start -->
                                    <div class="pricing-btn">
                                        <button ref="semiannual" class="btn-default" @click="setPrice('semi')"
                                            :class="paymentForm.isSemi ? 'btn-ingomugreen' : 'btn-ingomublue'">
                                            Get Started
                                        </button>
                                    </div>
                                    <!-- Pricing Button End -->
                                </div>
                                <!-- Pricing Box Content End -->

                                <!-- Pricing List Start -->
                                <div class="pricing-list">
                                    <ul>
                                        <li>Limited-Time Offer</li>
                                        <li>Save With a Semi-Annual Plan</li>
                                        <li>AI Coach Assistant</li>
                                        <li>Live Group Coaching</li>
                                        <li>Unlimited Access</li>
                                        <li>Coach Messaging</li>
                                    </ul>
                                </div>
                                <!-- Pricing List End -->
                            </div>
                            <!-- Pricing Box End -->

                            <!-- Pricing Box Start -->
                            <div class="pricing-box highlighted-box wow fadeInUp" data-wow-delay="0.2s">
                                <!-- Pricing Box Content Start -->
                                <div class="pricing-box-content">
                                    <!-- Pricing Title Start -->
                                    <div class="pricing-title">
                                        <p>Annual</p>
                                        <h2><sup>$</sup>{{ priceForm.annualPrice }}<sub>/year</sub></h2>
                                    </div>
                                    <!-- Pricing Title End -->

                                    <!-- Pricing Button Start -->
                                    <div class="pricing-btn">
                                        <button ref="annual" class="btn-default btn-highlighted"
                                            @click="setPrice('annual')"
                                            :class="paymentForm.isAnnual ? 'btn-ingomugreen' : 'btn-ingomublue'">
                                            Get Started
                                        </button>
                                    </div>
                                    <!-- Pricing Button End -->
                                </div>
                                <!-- Pricing Box Content End -->

                                <!-- Pricing List Start -->
                                <div class="pricing-list">
                                    <ul>
                                        <li>Limited-Time Offer</li>
                                        <li>Save With an Annual Plan</li>
                                        <li>AI Coach Assistant</li>
                                        <li>Live Group Coaching</li>
                                        <li>Unlimited Access</li>
                                        <li>Coach Messaging</li>
                                    </ul>
                                </div>
                                <!-- Pricing List End -->
                            </div>
                            <!-- Pricing Box End -->
                        </div>
                        <!-- Pricing Box List Start -->

                        <!-- Pricing Benifit List Start -->
                        <div class="pricing-benefit-list wow fadeInUp" data-wow-delay="0.6s">
                            <ul>
                                <!-- <li><img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-pricing-benefit-1.svg"
                                        alt="">Get a 7-day free trial</li> -->
                                <li><img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-pricing-benefit-2.svg"
                                        alt="">No hidden fees</li>
                                <li><img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-pricing-benefit-3.svg"
                                        alt="">Cancel anytime</li>
                            </ul>
                        </div>
                        <!-- Pricing Benifit List End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Pricing Section End -->

        <!-- Page Contact Us Start -->
        <div class="page-contact-us" :class="{ 'bg-section': isPaid, 'haswhite': isPaid, 'whiteremoved': !isPaid, 'mt-100': isPaid }" id="regsignup">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Contact Us Form Start -->
                        <div class="contact-us-form">
                            <!-- Contact Us Title Start -->
                            <div class="contact-us-title">
                                <h3 class="wow fadeInUp" v-if="isPaid">Create Your Ingomu Account</h3>
                                <h3 class="wow fadeInUp" v-else>Create Your Ingomu Account</h3>
                                <p class="wow fadeInUp" data-wow-delay="0.2s">Ingomu empowers growth with affordable coaching for everyone. Access expert coaches through live group sessions, on-demand resources, and AI-driven guidance. Enhance performance, improve well-being, and overcome challenges—all at a fraction of the cost. Sign up today to thrive in every aspect of life!</p>
                            </div>
                            <!-- Contact Us Title End -->

                            <!-- Signup Form Start -->
                            <form id="freeForm" action="#" method="POST" data-toggle="validator" class="wow fadeInUp"
                                data-wow-delay="0.4s" v-if="!isPaid">
                                <div class="row">

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="text" name="signupfirstname" class="form-control"
                                            id="signupfirstname" placeholder="First Name" required
                                            v-model="signupForm.signupfirstname">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="email" name="signupemail" class="form-control" id="signupemail"
                                            placeholder="Email Address" required v-model="signupForm.signupemail">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="password" name="signuppassword" class="form-control"
                                            id="signuppassword" placeholder="Password" required
                                            v-model="signupForm.signuppassword">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="password" name="signupverify" class="form-control"
                                            id="signupverify" placeholder="Verify Password" required
                                            v-model="signupForm.signupverify">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-12 mb-4">
                                        <input type="text" name="signupaccesscode" class="form-control"
                                            id="signupaccesscode" placeholder="Access Code" required
                                            v-model="signupForm.signupaccesscode">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-12 mb-5">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            By continuing I certify that I am 18 years of age or older, and I
                                            agree to the
                                            Ingomu <router-link to="/terms" target="_blank">terms & conditions
                                            </router-link> and Ingomu <router-link to="/terms#privacy"
                                                target="_blank">privacy
                                                policy</router-link>. If you already have an account, your existing
                                            password
                                            will be used to sign in.
                                        </label>
                                        <vue-honeypot ref="honeypot" />
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="contact-form-btn">
                                            <button @click.prevent="submitSignupForm" class="btn-default" ref="kt_save_signup" name="kt_save_signup">Sign Up</button>
                                            <div id="msgSubmit" class="h3 hidden"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- Signup Form End -->

                            <!-- Payment Form Start -->
                            <form id="paidForm" action="#" method="POST" data-toggle="validator" class="wow fadeInUp"
                                data-wow-delay="0.4s" v-else>
                                <div class="row">

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="text" name="paymentfirstname" class="form-control"
                                            id="paymentfirstname" ref="paymentfirstname" placeholder="First Name" required
                                            v-model="paymentForm.signupfirstname">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="email" name="paymentemail" class="form-control" id="paymentemail"
                                        ref="paymentemail" placeholder="Email Address" required v-model="paymentForm.signupemail">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="password" name="paymentpassword" class="form-control"
                                            id="paymentpassword" placeholder="Password" required
                                            ref="paymentpassword" v-model="paymentForm.signuppassword">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-6 mb-4">
                                        <input type="password" name="paymentverify" class="form-control"
                                            id="paymentverify" ref="paymentverify" placeholder="Verify Password" required
                                            v-model="paymentForm.signupverify">
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-12 mb-5">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            By continuing I certify that I am 18 years of age or older, and I
                                            agree to the
                                            Ingomu <router-link to="/terms" target="_blank">terms & conditions
                                            </router-link> and Ingomu <router-link to="/terms#privacy"
                                                target="_blank">privacy
                                                policy</router-link>. If you already have an account, your existing
                                            password
                                            will be used to sign in.
                                        </label>
                                        <vue-honeypot ref="honeypot" />
                                    </div>
                                </div>
                                <div class="mt-6" id="paypal-button-container"></div>
                            </form>
                            <!-- Payment Form End -->


                        </div>
                        <!-- Contact Us Form End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Contact Us End -->

        <!-- Our FAQs Section Start -->
        <div class="our-faqs" :class="{ 'bg-section': !isPaid, 'haswhite': !isPaid, 'whiteremoved': isPaid, 'mb-100': !isPaid }" id="faq">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <!-- Our FAQs Content Start -->
                        <div class="our-faqs-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">FAQ</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Answers to<br><span>Your
                                        Questions</span></h2>
                            </div>
                            <!-- Section Title End -->

                            <!-- FAQs CTA Box Start -->
                            <div class="faqs-cta-box wow fadeInUp" data-wow-delay="0.75s">
                                <!-- Company Client Images End -->
                                <div class="faqs-cta-content">
                                    <h3>Do you still have questions?</h3>
                                    <p>We're ready to help!</p>
                                    <router-link :to="{ name: 'speakercontact' }" class="btn-default mr-20">Contact
                                        Us</router-link>
                                </div>
                            </div>
                            <!-- FAQs CTA Box End -->
                        </div>
                        <!-- Our FAQs Content End -->
                    </div>

                    <div class="col-lg-6">
                        <div class="our-faq-section">
                            <!-- FAQ Accordion Start -->
                            <div class="faq-accordion" id="faqaccordion">
                                <!-- FAQ Item Start -->
                                <div class="accordion-item wow fadeInUp" v-for="(faq, index) in faqGeneral"
                                    :key="index">
                                    <h2 class="accordion-header" :id="faq.id">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" :data-bs-target="faq.target" aria-expanded="true"
                                            :aria-controls="faq.controls">
                                            {{ faq.question }}
                                        </button>
                                    </h2>
                                    <div :id="faq.controls" class="accordion-collapse collapse"
                                        :aria-labelledby="faq.id" data-bs-parent="#faqaccordion">
                                        <div class="accordion-body">
                                            <p v-html="faq.answer"></p>
                                        </div>
                                    </div>
                                </div>
                                <!-- FAQ Item End -->
                            </div>
                            <!-- FAQ Accordion End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Our FAQs Section End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/SignupHeader";
import SiteFooter from "@/components/InfineFooter";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import VueHoneypot from 'vue-honeypot'
import nl2brMixin from "@/mixins/nl2br";
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators';
import Swal from "sweetalert2";
// import { validator } from "../../../mixins/validator";

const TIMEOUT = 1000;

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    name: "companysignupv4",
    metaInfo() {
        return {
            title: "Register with " + this.name + " on Ingomu",
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        VueHoneypot
    },
    data() {
        return {
            videourl: "",
            videothumbnail: "",
            signupForm: {
                signupaccesscode: '',
                signupemail: '',
                signuppassword: '',
                signupverify: '',
                signupCorpId: '',
                signupClientIp: '',
                signupfirstname: ''
            },
            paymentForm: {
                signupemail: '',
                signuppassword: '',
                signupverify: '',
                signupCorpId: '',
                signupClientIp: '',
                signupfirstname: '',
                orderID: '',
                payerID: '',
                price: 0,
                isSemi: false,
                isAnnual: false,
            },
            loginForm: {
                accesscode: '',
                email: '',
                password: '',
                loginCorpId: '',
                loginClientIp: ''
            },
            forgotForm: {
                email: ''
            },
            priceForm: {
                annualPrice: 0,
                semiPrice: 0,
                bestValue: "annual"
            },
            corpImage: "https://dktoyr513tjgs.cloudfront.net/image/ingomu_logo-2.png",
            description: "",
            faqGeneral: [],
            show: "signup",
            video: "",
            name: "",
            headerTitle: "",
            isPaid: false,
            isMobile: false
        }
    },
    mixins: [nl2brMixin],
    validations() {
        return {
            signupForm: {
                signupaccesscode: { required },
                signupemail: { required, email },
                signuppassword: { required, minLength: minLength(6) },
                signupverify: { required, sameAsPassword: sameAs(this.signupForm.signuppassword) },
                signupfirstname: { required }
            },
            paymentForm: {
                signupemail: { required, email },
                signuppassword: { required, minLength: minLength(6) },
                signupverify: { required, sameAsPassword: sameAs(this.paymentForm.signuppassword) },
                signupfirstname: { required }
            },
            loginForm: {
                accesscode: { required },
                email: { required, email },
                password: { required, minLength: minLength(6) },
            },
            forgotForm: {
                email: { required, email }
            }
        }
    },
    methods: {
        checkIsMobile() {
            // Implement your mobile detection logic here, e.g., using window.innerWidth
            return window.innerWidth < 768;
        },
        setPrice(period) {
            if (period == "semi") {
                this.paymentForm.isSemi = true
                this.paymentForm.isAnnual = false
                this.$refs.semiannual.innerText = "Selected"
                this.$refs.annual.innerText = "Get Started"
                this.paymentForm.price = this.priceForm.semiPrice
            } else if (period == "annual") {
                this.paymentForm.isSemi = false
                this.paymentForm.isAnnual = true
                this.$refs.semiannual.innerText = "Get Started"
                this.$refs.annual.innerText = "Selected"
                this.paymentForm.price = this.priceForm.annualPrice
            }
            setTimeout(function () {
                const yOffset = -100;
                const element = document.getElementById("regsignup");
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }, 100);
        },
        async submitSignupForm() {
            try {
                this.$refs.honeypot.validate()
                let self = this;
                const isFormCorrect = await this.v$.signupForm.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (!isFormCorrect) {
                    Swal.fire({
                        title: "",
                        text: "Please, complete all fields.",
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
                    return
                }
                // actually submit form
                const submitButton = this.$refs["kt_save_signup"];
                submitButton.classList.add("disabled");
                this.$store.dispatch("signupCorpUser", this.signupForm).then(
                    function (value) {
                        self.$router.replace({ name: 'companythankyou', params: { slug: self.$route.params.slug, codegroupid: value } })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "disabled"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });

            } catch (error) {
                // error handling
                console.log("faild validation, alert user")
            }
        },
        async resetForm() {
            let self = this;
            const isFormCorrect = await this.v$.forgotForm.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) {
                Swal.fire({
                    title: "",
                    text: "Please, complete all fields.",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
                return
            }
            const submitButton = this.$refs["kt_forgot_send"];
            submitButton.classList.add("disabled");
            this.$store.dispatch("forgotPassword", this.forgotForm).then(
                function (value) {
                    submitButton.classList.remove(
                        "disabled"
                    );
                    Swal.fire({
                        title: "",
                        text: "A reset password email will be sent if that email exists in our system.",
                        icon: "success",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
                },
                function (error) {
                    submitButton.classList.remove(
                        "disabled"
                    );
                    Swal.fire({
                        title: "",
                        text: error,
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
                })
        },
        createOrder: async function (data, actions) {
            if (this.paymentForm.isSemi == true) {
                var setPrice = this.corpDetails[0].semi
            } else {
                var setPrice = this.corpDetails[0].price
            }
            return actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            value: setPrice,
                        },
                    },
                ],
            });
        },
        onApprove: function (data, actions) {
            this.paymentForm.orderID = data.orderID
            this.paymentForm.payerID = data.payerID
            let self = this;

            return actions.order.capture().then(function () {
                self.$store.dispatch("signupPaymentUser", self.paymentForm).then(
                    function (value) {
                        // self.$mixpanel.track('referral_purchase', {
                        //     'action': 'purchased',
                        //     'date': new Date().toString(),
                        //     'slug': self.$route.params.slug,
                        //     'price': self.paymentForm.price,
                        //     'isAnnual': self.paymentForm.isAnnual,
                        //     'isSemi': self.paymentForm.isSemi
                        // });
                        if (self.paymentForm.isSemi == true) {
                            self.$router.replace({ name: 'companythankyoupaid', params: { slug: self.$route.params.slug, term: "semi" } })
                        } else {
                            self.$router.replace({ name: 'companythankyoupaid', params: { slug: self.$route.params.slug, term: "annual" } })
                        }
                    },
                    function (error) {
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
            });
        },
        scrollTo(hashtag) {
            // setTimeout(() => { document.getElementById(hashtag.slice(1)).scrollIntoView() }, TIMEOUT)
            setTimeout(() => {
                const element = document.getElementById(hashtag.slice(1)); // Your target element
                const headerOffset = 150;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }, TIMEOUT)
        },

    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        this.$store.dispatch("getCorpDetails", { slug: this.$route.params.slug })
        this.$store.dispatch("getPricing")

        this.isMobile = this.checkIsMobile();
        window.addEventListener('resize', this.checkIsMobile);

        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(response => {
                this.signupForm.signupClientIp = response.ip;
                this.paymentForm.signupClientIp = response.ip;
                this.loginForm.loginClientIp = response.ip;
            }).catch(error => {
                console.log(error)
            });
        // this.$mixpanel.track('referral', {
        //     'action': 'viewed',
        //     'date': new Date().toString(),
        //     'slug': this.$route.params.slug
        // });

        var $window = $(window);

        // /* Preloader Effect */
        // $window.on('load', function () {
        //     $(".preloader").fadeOut(600);
        // });

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            // $('.popup-video').magnificPopup({
            //     type: 'iframe',
            //     mainClass: 'mfp-fade',
            //     removalDelay: 160,
            //     preloader: false,
            //     fixedContentPos: true
            // });

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()

            // validator((freeForm) => {
            //     console.log(freeForm)
            //     // let name = contactForm[0]["value"] + " " + contactForm[1]["value"];
            //     // let email = contactForm[2]["value"];
            //     // let phone = contactForm[3]["value"];
            //     // let message = contactForm[4]["value"];
            //     // $this.submit(name, email, phone, message);
            // })
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkIsMobile);
    },
    computed: {
        ...mapGetters(["corpDetails", "monthlyPrice"]),
        faqs() {
            return this.faqGeneral
        },
    },
    watch: {
        corpDetails(newValue) {
            if (newValue.length <= 0) {
                this.$router.push({ path: '/404' })
            } else {
                if (newValue[0].corpimage != "" && newValue[0].corpimage != null) {
                    this.corpImage = newValue[0].corpimage;
                }
                this.name = newValue[0].name
                this.headerTitle = newValue[0].name.replaceAll("-", " ")
                this.description = newValue[0].description
                this.isPaid = newValue[0].isPaid

                setTimeout(() => {
                    const info = { page_path: location.pathname, page_title: newValue[0].name, page_location: location.href }
                    this.$gtag.pageview(info)
                }, 1000)
                // this.paymentForm.price = newValue[0].price
                this.signupForm.signupCorpId = newValue[0].corporate_id
                this.paymentForm.signupCorpId = newValue[0].corporate_id
                this.loginForm.loginCorpId = newValue[0].corporate_id
                this.priceForm.annualPrice = newValue[0].price
                this.priceForm.semiPrice = newValue[0].semi
                this.priceForm.bestValue = newValue[0].bestvalue
                if (newValue[0].video != "" && newValue[0].video != null && newValue[0].videothumbnail != "" && newValue[0].videothumbnail != null) {
                    this.videourl = newValue[0].video
                    this.videothumbnail = newValue[0].videothumbnail
                }
                if (newValue[0].faq && newValue[0].faq.length > 0) {
                    var faqArray = []
                    newValue[0].faq.forEach((doc, i) => {
                        var showHide = ""
                        if (i == 0) { showHide = "show" }
                        faqArray.push({
                            question: doc.question,
                            answer: doc.answer,
                            id: "heading" + i.toString(),
                            target: "#collapse" + i.toString(),
                            controls: "collapse" + i.toString(),
                            show: showHide,
                        })
                    })
                    this.faqGeneral = faqArray
                }
                if (newValue[0].isPaid) {
                    this.$loadScript({ "client-id": this.$clientId }).then((paypal) => {
                        let self = this;
                        paypal
                            .Buttons({
                                onClick: async function (data, actions) {
                                    const isFormCorrect = await self.v$.paymentForm.$validate()
                                    if (!isFormCorrect) {
                                        Swal.fire({
                                            title: "",
                                            text: "Please, complete all fields.",
                                            icon: "error",
                                            confirmButtonClass: "btn btn-secondary",
                                            heightAuto: true,
                                        });
                                        return false
                                    } else {
                                        return true
                                    }
                                },
                                createOrder: this.createOrder,
                                onApprove: this.onApprove,
                            })
                            .render("#paypal-button-container");
                    });
                }
            }
        },
        videourl(newValue) {
            if (newValue) {
                $(function () {
                    /* Preloader Effect */
                    setTimeout(function () {
                        $(".preloader").fadeOut(600);
                    }, 600);

                    $('.popup-video').magnificPopup({
                        type: 'iframe',
                        mainClass: 'mfp-fade',
                        removalDelay: 160,
                        preloader: false,
                        fixedContentPos: true
                    });
                });
            }
        }
    }
}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu.signup.blue.png);
}

.post-featured-image figure a {
    cursor: pointer;
}

.haswhite {
    background: var(--white-color);
    
}

.our-approach,
.our-pricing {
    background: none;
}

.pb-0 {
    padding-bottom: 0;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.p-50 {
    padding: 50px;
}

.whiteremoved {
    background-color: inherit;
}

.post-content {
    max-width: none;
}

.video-play-button {
    justify-content: start;
}

.service-single-content {
    background: var(--white-color);
    padding: 50px;
}

.w-100 {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.mt-16 {
    margin-top: 16px;
}

.sidebar-cta-Body {
    background: black;
    padding: 0;
}

.w-auto {
    width: auto !important;
    margin: auto;
}

.drk {
    filter: brightness(0.6);
}

.contact-us-form {
    background-color: inherit;
    padding: 0;
}

@media (max-width:991px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu.signup.blue.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .service-catagery-list {
        margin-top: 30px;
    }
}
</style>