<template>
    <!-- Header Start -->
    <header class="main-header">
        <div class="header-sticky">
            <nav class="navbar navbar-expand-lg">
                <div class="container-fluid">
                    <!-- Logo Start -->
                    <router-link :to="{ name: 'home' }" class="navbar-brand">
                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Website-Header-Logo.png" alt="Logo">
                    </router-link>
                    <!-- Logo End -->

                    <!-- Main Menu Start -->
                    <div class="collapse navbar-collapse main-menu">
                        <div class="nav-menu-wrapper">
                            <ul class="navbar-nav mr-auto" id="menu">
                                <li class="nav-item"><router-link :to="{ name: 'companysignupv4', hash: '#description' }" @click.native="scrollTo('description')" class="nav-link">About</router-link></li>
                                <li class="nav-item" v-if="isPaid"><router-link :to="{ name: 'companysignupv4', hash: '#pricing' }" @click.native="scrollTo('pricing')" class="nav-link">Pricing</router-link></li>
                                <li class="nav-item"><router-link :to="{ name: 'companysignupv4', hash: '#regsignup' }" @click.native="scrollTo('regsignup')" class="nav-link">Sign up</router-link></li>
                                <li class="nav-item"><router-link :to="{ name: 'companysignupv4', hash: '#faq' }" @click.native="scrollTo('faq')" class="nav-link">FAQ</router-link></li>
                            </ul>
                        </div>
                        <!-- Header Btn Start -->
                        <div class="header-btn d-inline-flex">
                            <router-link :to="{ name: 'contact' }" class="btn-default textnone">Get in Touch</router-link>
                        </div>
                        <!-- Header Btn End -->
                    </div>
                    <!-- Main Menu End -->
                    <div class="navbar-toggle"></div>
                </div>
            </nav>
            <div class="responsive-menu"></div>
        </div>
    </header>
    <!-- Header End -->
</template>

<script>
const TIMEOUT = 1;

export default {
name: "InfineHeader",
props: {
        isPaid: {
            type: Boolean,
            default: false
        }
    },
mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
          if (window.location.hash === this.$route.hash) {
              const el = document.getElementById(this.$route.hash.slice(1))
              if (el) {
                  window.scrollTo(0, el.offsetTop + 200)
              }
          }
      }, TIMEOUT)
  }
},
methods: {
    scrollTo(hashtag) {
      document.getElementById(hashtag).scrollIntoView({ behavior: 'smooth' })
  },
}
}
</script>
<style lang="css" scoped>
.textnone {
text-transform: none;
}
</style>