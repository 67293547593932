import * as fb from '@/api/firebase.js';
import { data } from 'jquery';
import { query } from 'vue-gtag';

const state = {
    corpDetails: [],
    codeGroupDetails: [],
    monthlyPrice: 0,
    semiPrice: 0,
    annualPrice: 0
}

const getters = {
    corpDetails: state => state.corpDetails,
    codeGroupDetails: state => state.codeGroupDetails,
    monthlyPrice: state => state.monthlyPrice,
    semiPrice: state => state.semiPrice,
    annualPrice: state => state.annualPrice
}

const actions = {
    generateCoachList(context) {
        createNewCompanyPaidUser({})
    },
    getPricing(context) {
        fb.pricingCollection
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.size > 0) {
                    querySnapshot.forEach((doc) => {
                        const data = doc.data()
                        state.monthlyPrice = data.monthly
                        state.semiPrice = data.semi
                        state.annualPrice = data.annual
                    })
                }
            })
    },
    getCorpDetails(context, payload) {
        fb.corpClientsCollection
            .where("slug", "==", payload.slug.toLowerCase())
            .where("online", "==", true)
            .onSnapshot((querySnapshot) => {
                let codeArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    codeArray.push(data)
                })
                state.corpDetails = codeArray;
            });
    },
    getCodeGroupListDetails(context, payload) {
        fb.corpClientsCollection
            .doc(payload.corpId)
            .collection("code_group")
            .doc(payload.codegroupId)
            .onSnapshot((doc) => {
                state.codeGroupDetails = doc.data()
            });
    },
    signupPaymentUser(context, form) {
        return new Promise((resolve, reject) => {
            let formData = {
                email: form.signupemail,
                password: form.signuppassword,
                corpid: form.signupCorpId,
                ip: form.signupClientIp,
                firstname: form.signupfirstname,
                orderid: form.orderID,
                payerid: form.payerID,
                price: form.price,
                isSemi: form.isSemi
            }

            var createNewCompanyPaidUser = fb.functions.httpsCallable("companypaiduser");
            createNewCompanyPaidUser(formData)
            resolve()
        })
    },
    signupCorpUser(context, form) {
        return new Promise((resolve, reject) => {
            const startOfDay = new Date();
            startOfDay.setHours(7, 0, 0, 0);
            fb.corpCodesCollections
                .where("code", "==", form.signupaccesscode.trim())
                .where("startdate", "<=", startOfDay)
                .where("corporate_id", "==", form.signupCorpId)
                .where("isused", "==", false)
                .where("online", "==", true)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.size > 0) {
                        var groupId = ""
                        querySnapshot.forEach((doc) => {
                            const data = doc.data()
                            groupId = data.codegroup_id
                        })

                        let formData = {
                            code: form.signupaccesscode,
                            email: form.signupemail,
                            password: form.signuppassword,
                            corpid: form.signupCorpId,
                            ip: form.signupClientIp,
                            firstname: form.signupfirstname,
                            codegroupid: groupId
                        }

                        var createNewCompanyUser = fb.functions.httpsCallable("companypromouser");
                        createNewCompanyUser(formData)
                        resolve(groupId)
                    } else {
                        console.log("reject code is invalid")
                        reject("That code is invalid.")
                    }
                })
                .catch((error) => {
                    console.log("Error getting document:", error);
                    reject("That code is invalid.")
                });
        });
    }
}

export default {
    state,
    getters,
    actions
}
