import Vue from 'vue'
import App from './App.vue'
import router from './router'
import businessrouter from './router/business'
import store from './store'
import VueExpandableImage from 'vue-expandable-image'
import VeeValidate from 'vee-validate';
import VueSimpleAlert from "vue-simple-alert";
import VueMeta from 'vue-meta'
import SmartBanner from 'smart-app-banner'
import 'smart-app-banner/dist/smart-app-banner.css'
import { loadScript } from '@paypal/paypal-js'
import ToggleButton from 'vue-js-toggle-button'
import VueMasonry from 'vue-masonry-css'
import VueGtag from 'vue-gtag'

Vue.use(VueMasonry);
Vue.use(ToggleButton)
Vue.use(VueExpandableImage)
Vue.use(VeeValidate);
Vue.use(VueSimpleAlert);
Vue.use(VueMeta)
Vue.prototype.$loadScript = loadScript
if (Vue.config.devtools) {
  Vue.prototype.$clientId = "AcqKaqpjGBLzhpCkdjgZYglJBi_hhWwsCdfzvl_F7XwvZ70VA_ZIrYtgySl7IQ4dqyKfLZnMAM46tc6g"
} else {
  Vue.prototype.$clientId = "AW12WXUtnMRa34DUDNF-SVV6YCYDB7RoxyHIaKM9jw_c0_cpKR_VtCzTjjXbUbY5OkV7UwXuRsnByOxQ"
}

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
      id: 'G-9N0ZE9CSDV',
      params: {
          send_page_view: false
      }
  }
}, router)

// import VueMixpanelBrowser from 'vue-mixpanel-browser';
// Vue.use(VueMixpanelBrowser, {
//   token: "800e02b4e1962021aaf2e6989b36e915",
//   config: { debug: true }
// });


const host = window.location.host;
const parts = host.split('.');
const domainLength = 3; // route1.example.com => domain length = 3

const dynamicrouter = () => {
  let routes;
  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    routes = router;
  } else if (parts[0] === 'coaching') {
    routes = businessrouter;
  } else {
    // If you want to do something else just comment the line below
    routes = router;
  }
  return routes;
};

document.addEventListener("DOMContentLoaded", async function (event) {
  new Vue({
    router: dynamicrouter(),
    store,
    render: h => h(App),
    created () {
      new SmartBanner({
        daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        title: 'Ingomu&reg;',
        author: 'Growth, Thrive, and Succeed',
        button: 'GET',
        store: {
          ios: 'On the App Store',
          android: 'In Google Play'
        },
        price: {
          ios: 'FREE',
          android: 'FREE',
          windows: 'FREE'
        }
        //, theme: 'ios' // put platform type ('ios', 'android', etc.) here to force single theme on all device
       , icon: 'https://dktoyr513tjgs.cloudfront.net/image/180.png' // full path to icon image if not using website icon image
      })
    }
  }).$mount('#app');
})