import { render, staticRenderFns } from "./SignupHeader.vue?vue&type=template&id=77c82abc&scoped=true&"
import script from "./SignupHeader.vue?vue&type=script&lang=js&"
export * from "./SignupHeader.vue?vue&type=script&lang=js&"
import style0 from "./SignupHeader.vue?vue&type=style&index=0&id=77c82abc&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c82abc",
  null
  
)

export default component.exports