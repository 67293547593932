import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home_v4.vue'
import * as fb from '@/api/firebase'
import { version } from "../../package.json";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/pages/business/business_v4.vue"),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import("@/views/pages/business/business_v4.vue"),
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import("@/views/pages/business/business_v4.vue"),
  },
  {
    path: '/business',
    name: 'business',
    component: () => import("@/views/pages/business/business_v4.vue"),
  },
  {
    path: '/business/newsletter',
    name: 'newsletter',
    component: () => import("@/views/pages/business/newsletter.vue"),
  },
  {
    path: '/terms',
    name: 'terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/terms_v4.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: '/for-employers',
    name: 'employers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/business.vue'),
    redirect: to => {
      return 'index'
    }
  },
  {
    path: '/for-higher-education',
    name: 'education',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/education.vue'),
    redirect: to => {
      return 'index'
    }
  },
  {
    path: '/speakin',
    name: 'speakin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: '/speaking',
    name: 'speaking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "/admin/blog/preview/:slug",
    name: 'blogpreview',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "/blog/category/:category",
    name: 'blogcategory',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "/blog/tags/:tag",
    name: 'blogtags',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "/blog/:slug",
    name: 'blogdetail',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "/blog",
    name: 'blog',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "about" */ '../views/error_v4.vue'),
  },
  {
    path: "/complete/:slug/:codegroupid",
    name: 'companythankyou',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "/completed/:slug/:term",
    name: 'companythankyoupaid',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "/:slug",
    name: 'companysignup',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://ingomu.com" + to.path)
    },
  },
  {
    path: "*",
    redirect: "/404"
  },
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };  // Go to the top of the page if no hash
  },
  base: process.env.BASE_URL,
  routes,
  mode: 'history',
})

// navigation guard to check for logged in users
router.beforeEach(async (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  fb.analytics.logEvent("page_view")
  fb.analytics.logEvent("screen_view", {
    app_name: "web",
    screen_name: to.name,
    app_version: version
  });
  // if (to.fullPath.substr(0, 2) !== "/#") {
  //   if (to.fullPath.substr(0, 7) !== "/terms#") {
  //     setTimeout(() => {
  //       window.scrollTo(0, 0);
  //     }, 100);
  //     next();
  //     return;
  //   }

  // }
  next();
})

export default router
