
export function parrallaxie() {
    var $window = $(window);
    
    $.fn.parallaxie = function (options) {

        var options = $.extend({
            speed: 0.2,
            repeat: 'no-repeat',
            size: 'cover',
            pos_x: 'center',
            offset: 0,
        }, options);

        this.each(function () {

            var $el = $(this);
            var local_options = $el.data('parallaxie');
            if (typeof local_options != 'object') local_options = {};
            local_options = $.extend({}, options, local_options);

            var image_url = $el.data('image');
            if (typeof image_url == 'undefined') {
                image_url = $el.css('background-image');
                if (!image_url) return;

                // APPLY DEFAULT CSS
                var pos_y = local_options.offset + ($el.offset().top - $(window).scrollTop()) * (1 - local_options.speed);
                $el.css({
                    'background-image': image_url,
                    'background-size': local_options.size,
                    'background-repeat': local_options.repeat,
                    'background-attachment': 'fixed',
                    'background-position': local_options.pos_x + ' ' + pos_y + 'px',
                });

                $(window).scroll(function () {
                    //var pos_y = - ( $(window).scrollTop() - $el.offset().top ) * ( 1 + local_options.speed ) - ( $el.offset().top * local_options.speed );
                    var pos_y = local_options.offset + ($el.offset().top - $(window).scrollTop()) * (1 - local_options.speed);
                    $el.data('pos_y', pos_y);
                    $el.css('background-position', local_options.pos_x + ' ' + pos_y + 'px');
                }
                );
            }
        });
        return this;
    };

    /* Parallaxie js */
    var $parallaxie = $('.parallaxie');
    if ($parallaxie.length && ($window.width() > 991)) {
        if ($window.width() > 768) {
            $parallaxie.parallaxie({
                speed: 0.55,
                offset: 0,
            });
        }
    }
}